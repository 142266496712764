import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {nullifyCategoryListStore} from "../../../store/categories/actions/CategoriesActions";
import {fetchViewCountForCategory} from "../../../store/viewCount/actions/ViewCountActions";
import OverviewStats from "./Components/OverviewStats";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
const ServiceWrapper = WithServiceState(OverviewStats);

const Overview = () => {
    const dispatch = useDispatch();
    const viewCountState = useSelector((state: RootStore) => state.viewCount);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(fetchViewCountForCategory());

        return () => {
            dispatch(nullifyCategoryListStore());
        };
    }, []);
    return (
        <React.Fragment>
            <TopBar titleText="Documents Overview" />
            <div className="page-container mt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    {...viewCountState}
                />
            </div>
        </React.Fragment>
    );
};

export default Overview;
