import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import StaffComplianceContainer from "./Components/StaffComplianceContainer";
import {fetchAllStaff} from "../../../store/staffList/actions/StaffListActions";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const StaffCompliance = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];
        dispatch(fetchAllStaff());

        dispatch(setNavigationItems(navItems));
    }, []);

    return (
        <>
            <TopBar titleText="Staff Compliance" />
            <div className="page-container mt-4">
                <StaffComplianceContainer />
            </div>
        </>
    );
};

export default StaffCompliance;
