import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    createNewCategory,
    nullifyCategoryStore
} from "../../../store/category/actions/CategoryActions";
import EditCategoryForm from "./Components/EditCategoryForm";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const EditFormWrapper = WithServiceState(EditCategoryForm);

const AddCategory = () => {
    const dispatch = useDispatch();
    const categoryStore = useSelector((state: RootStore) => state.category);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(createNewCategory());

        return () => {
            dispatch(nullifyCategoryStore());
        };
    }, []);

    return (
        <>
            <TopBar titleText="Add Category" />
            <div className="page-container">
                <EditFormWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    {...categoryStore}
                />
            </div>
        </>
    );
};

export default AddCategory;
