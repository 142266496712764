import React, {useEffect} from "react";
import {CategoryViewStat} from "../../../../api/ppb";
import {useDispatch} from "react-redux";
import {nullifyViewCountStore} from "../../../../store/viewCount/actions/ViewCountActions";
import {toArray} from "../../../../utils/sortingUtils";
import {PieChart} from "react-minimal-pie-chart";
import FormHeader from "../../../Form/FormHeader";
import {green, red, yellow} from "../../../../utils/colourUtils";
import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";

const OverviewStats = (props: CategoryViewStat[]) => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(nullifyViewCountStore());
        };
    }, []);
    return (
        <React.Fragment>
            <FormHeader headerName={"My Documents Compliance Stats"} />
            <div className="stat-item-wrapper">
                {toDonutChartProps(toArray(props)).map((item, index) => {
                    return (
                        <div className="stat-item mb-4" key={index}>
                            <PieChart
                                animationDuration={500}
                                animationEasing={"ease-out"}
                                center={[50, 50]}
                                data={item.donutChart}
                                paddingAngle={0}
                                viewBoxSize={undefined}
                                lineWidth={12}
                                startAngle={0}
                                totalValue={item.possibleViews}
                                className="w-100"
                                label={({dataEntry}) =>
                                    item.possibleViews === 0
                                        ? "No files"
                                        : Math.round(dataEntry.percentage) > 0
                                        ? `${Math.round(dataEntry.percentage)}%`
                                        : ""
                                }
                                labelStyle={(idx) => ({
                                    fill: item.donutChart[idx].color,
                                    fontSize: "12px",
                                    fontFamily: "sans-serif"
                                })}
                                labelPosition={setLabelPosition(item)}
                            />
                            <p className="text-center pt-2">
                                <p className="category-title">{item.categoryName}</p>
                            </p>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default OverviewStats;

export function toDonutChartProps(viewStats: CategoryViewStat[]): DonutChartList[] {
    return viewStats.map((stat) => {
        return {
            categoryName: stat.categoryName,
            possibleViews: stat.possibleViews,
            donutChart: getDonutChartStats(stat)
        };
    });
}

function getDonutChartStats(stat: CategoryViewStat): DataEntry[] {
    if (stat.possibleViews === 0) {
        return [
            {
                title: "None",
                value: 1,
                color: red
            }
        ];
    }

    return Object.keys(DocumentState).map((item) => {
        return {
            title: item,
            value: getValueForStat(stat, DocumentState[item as keyof typeof DocumentState]),
            color: getColourForStat(DocumentState[item as keyof typeof DocumentState])
        };
    });
}

function getValueForStat(stat: CategoryViewStat, documentState: DocumentState): number {
    switch (documentState) {
        case DocumentState.Read:
            return stat.views;
        case DocumentState.Updated:
            return stat.oldViews;
        case DocumentState.Unread:
            return stat.possibleViews - (stat.oldViews + stat.views);
    }
}

function getColourForStat(documentState: DocumentState): string {
    switch (documentState) {
        case DocumentState.Read:
            return green;
        case DocumentState.Updated:
            return yellow;
        case DocumentState.Unread:
            return red;
    }
}

export interface DonutChartList {
    donutChart: DataEntry[];
    categoryName: string;
    possibleViews: number;
}

// eslint-disable-next-line no-shadow
enum DocumentState {
    Read = "Read",
    Updated = "Updated",
    Unread = "Unread"
}

function setLabelPosition(chart: DonutChartList): number {
    //60 = offset. 0 = center

    for (const entry of chart.donutChart) {
        //Check if the value of the entry is equal to the user count (100%). If it is, return 0 (center position)
        if (entry.value === chart.possibleViews) {
            return 0;
        }
    }

    //Return offset
    return 60;
}
