import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../store/Store";
import {fetchDocumentsForStaffMember} from "../../../store/userDocuments/actions/UserDocumentsActions";

import {WithServiceState} from "store-fetch-wrappers";
import {useUserData} from "../../Hooks/useUserData";
import MyDocumentList from "./MyDocumentsList";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {McConfig} from "../../../McConfigPlugin";
import useMcConfig from "../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../utils/userDataUtils";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const UserDocumentListWrapper = WithServiceState(MyDocumentList);

const ViewUserDocumentsByCategory = () => {
    const user = useUserData();
    const userDocStoreState = useSelector((state: RootStore) => state.userDocuments);
    const dispatch = useDispatch();
    const {fullPath} = usePageUrl();
    const {config} = useMcConfig();
    useEffect(() => {
        if (!config.auth) return;
        if (user.username.length === 0) return;
        //Set Nav Items
        dispatch(setNavigationItems(getNavItemsForUserForMyDocuments(user, fullPath, config)));
        dispatch(fetchDocumentsForStaffMember(user.username));
    }, [user, config]);

    return (
        <>
            <TopBar titleText="My Documents in Category" />
            <div className="page-container mt-4">
                <UserDocumentListWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    {...userDocStoreState}
                />
            </div>
        </>
    );
};

export default ViewUserDocumentsByCategory;

function getNavItemsForUserForMyDocuments(
    user: UserData,
    fullPath: string,
    config: McConfig
): NavigationItem[] {
    const accessLevel = getUserAccessLevel(user, config);
    switch (accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.overview.name,
                    path: routeNames.overview.path
                },
                {
                    name: routeNames.categoryList.name,
                    path: routeNames.categoryList.path
                },
                {
                    name: routeNames.documentList.name,
                    path: routeNames.documentList.path
                },
                {
                    name: routeNames.myDocuments.name,
                    path: fullPath
                },
                {
                    name: routeNames.staffCompliance.name,
                    path: routeNames.staffCompliance.path
                }
            ];
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return [
                {
                    name: routeNames.myDocuments.name,
                    path: routeNames.myDocuments.path
                }
            ];
    }
}
