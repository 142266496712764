import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers";
import {useParams} from "react-router-dom";
import EditDocumentForm from "./Components/EditDocumentForm";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {
    getDocumentById,
    nullifyDocumentStore
} from "../../../store/document/actions/DocumentActions";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../Hooks/usePageUrl";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const EditDocumentFormWrapper = WithServiceState(EditDocumentForm);

const EditDocument = () => {
    const dispatch = useDispatch();
    const documentStore = useSelector((state: RootStore) => state.document);
    const params: any = useParams();
    const {fullPath} = usePageUrl();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];
        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getDocumentById(+params.id));
        return () => {
            dispatch(nullifyDocumentStore());
        };
    }, []);

    return (
        <>
            <TopBar titleText="Edit Document" />
            <div className="page-container">
                <EditDocumentFormWrapper
                    placeholderBlockCount={1}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    showLoadingText={true}
                    {...documentStore}
                />
            </div>
        </>
    );
};

export default EditDocument;
