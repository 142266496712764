import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers";
import ViewDocumentsCategoryButtonList, {
    ViewDocumentsCategoryButtonListProps
} from "./ViewDocumentsCategoryButtonList";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getCategoriesList} from "../../../store/categories/actions/CategoriesActions";
import {fetchViewCountForCategory} from "../../../store/viewCount/actions/ViewCountActions";
import {StoreServiceData} from "store-fetch-wrappers/dist";
import {Category} from "../../../api/ppb";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ViewDocumentListTableWrapper = WithServiceState(ViewDocumentsCategoryButtonList);

/** Landing page for PPB */
const ViewDocumentList = () => {
    const dispatch = useDispatch();

    const categoriesState = useSelector((state: RootStore) => state.categories);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.documentList.name,
                path: routeNames.documentList.path
            },
            {
                name: routeNames.myDocuments.name,
                path: routeNames.myDocuments.path
            },
            {
                name: routeNames.staffCompliance.name,
                path: routeNames.staffCompliance.path
            }
        ];

        dispatch(setNavigationItems(navItems));

        dispatch(fetchViewCountForCategory());

        dispatch(getCategoriesList());
    }, []);

    return (
        <>
            <TopBar titleText="View Documents" />
            <div className="page-container mt-4">
                <ViewDocumentListTableWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    data={getDataForDocumentListButtons(categoriesState, false)}
                    loading={categoriesState.loading}
                    error={categoriesState.error}
                />
            </div>
        </>
    );
};

export default ViewDocumentList;

export function getDataForDocumentListButtons(
    data: StoreServiceData<Category[]>,
    isMyDocumentsRoute: boolean
): ViewDocumentsCategoryButtonListProps {
    return {
        categoryList: data.data || [],
        isMyDocumentsRoute
    };
}
